.block_services {

	text-align: center;
	border-top: 5px solid $color-secondary;
	border-bottom: 5px solid $color-secondary;
	background-color: $white;

	img {
		max-width: 64px;
		margin: 0 auto 1em;
	}

	ul li {
		display: inline-block;
		padding: 0.5em;
		font-size: 15px;
		font-weight: 600;
		line-height: 1em;
		letter-spacing: -0.03em;
		text-align: center;
		text-transform: uppercase;
	}

	@include media-breakpoint-up(md) {

		ul li {
			display: block;
			float: left;
			width: 20%;
		}

	}

}
