.widget {
	@extend %card;
	margin: 0 0 15px;
	padding: 15px;
	font-size: 85%;
	border: 1px solid #ddd;
	background-color: #fff;

	> *:last-child { margin-bottom: 0; }

	&.widget-default { .widget-title { color: $color-text; } }
	&.widget-text { border-color: $color-text; .widget-title { background-color: $color-text; } }
	&.widget-primary { border-color: $color-primary; .widget-title { background-color: $color-primary; } }
	&.widget-secondary { border-color: $color-secondary; .widget-title { background-color: $color-secondary; } }	
	&.widget-highlight { border-color: $color-highlight; .widget-title { background-color: $color-highlight; } }
	&.widget-link { border-color: $color-link; .widget-title { background-color: $color-link; } }
	&.widget-review { border-color: $color-review; .widget-title { background-color: $color-review; } }
	&.widget-success { border-color: $color-success; .widget-title { background-color: $color-success; } }
	&.widget-danger { border-color: $color-danger; .widget-title { background-color: $color-danger; } }
	&.widget-info { border-color: $color-info; .widget-title { background-color: $color-info; } }
	&.widget-warning { border-color: $color-warning; .widget-title { background-color: $color-warning; } }

}

	.widget-title:not(.plain) {
		margin: -15px -15px 15px;
		padding: 12px 15px;
		font-size: 1.5rem;
		font-weight: 600;
		background-color: #ddd;
		color: #fff;
	}

		.widget-title.plain { margin: 0 0 5px; }

.widget_plain {
	padding: 0;
	border: none;
	box-shadow: none;
	background-color: transparent;
	.widget-title {
		margin: 0 0 15px;
		padding: 0px;
		background-color: transparent;
		color: inherit;
	}
}

	.widget-rwreviews {

		@include media-breakpoint-only(md) {
			overflow: hidden;
			p {
				float: left;
				width: 49%;
				&:nth-child(odd) { clear: both; }
				&:nth-child(even) { float: right;; }
			}
		}

	}

	.widget-pleads,
	.widget-plogos {
		max-width: 360px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		border-width: 3px;
		border-color: #07b;
		.widget-title { background-color: #07b; }
		img {
			max-width: 240px;
			margin: 0 auto 0.5em;
		}
	}

	.widget-plogos {
		padding: 0;
		border-color: #f7921e;
		text-align: right;
		a {
			display: block;
			padding: 1rem;
			text-decoration: none;
			color: darken(#f7921e, 10%);
			img {
				max-width: 360px;
				margin: 0 auto;
			}
		}
	}


	.widget-cities {
		.nav_menu > .menu_item {
			> .menu_link {
				padding: 0.75em 0.5em;
				border-left: 10px solid transparent;
			}
			&.active > .menu_link {
				background-color: $white;
				color: $color-text;
				font-weight: 600;
				&:hover { border-color: transparent !important; }
			}
		}
		&.nav--primary {
			.nav_menu > .menu_item > .menu_link:hover { border-color: darken($color-primary, 5%); }
		}
		&.nav--secondary {
			.nav_menu > .menu_item > .menu_link:hover { border-color: darken($color-secondary, 5%); }
		}
		&.nav--highlight {
			.nav_menu > .menu_item > .menu_link:hover { border-color: darken($color-highlight, 5%); }
		}
		&.nav--accent {
			.nav_menu > .menu_item > .menu_link:hover { border-color: darken($color-accent, 5%); }
		}
		&.nav--text {
			.nav_menu > .menu_item > .menu_link:hover { border-color: darken($color-text, 5%); }
		}
		&.nav--link {
			.nav_menu > .menu_item > .menu_link:hover { border-color: darken($color-link, 5%); }
		}
		&.nav--success {
			.nav_menu > .menu_item > .menu_link:hover { border-color: darken($color-success, 5%); }
		}
		&.nav--danger {
			.nav_menu > .menu_item > .menu_link:hover { border-color: darken($color-danger, 5%); }
		}
		&.nav--warning {
			.nav_menu > .menu_item > .menu_link:hover { border-color: darken($color-warning, 5%); }
		}
		&.nav--info {
			.nav_menu > .menu_item > .menu_link:hover { border-color: darken($color-info, 5%); }
		}

	}