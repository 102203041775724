.hidden--xs { @include media-breakpoint-down(xs) { display: none !important; } }
.hidden--sm { @include media-breakpoint-only(sm) { display: none !important; } }
.hidden--md { @include media-breakpoint-only(md) { display: none !important; } }
.hidden--lg { @include media-breakpoint-only(lg) { display: none !important; } }
.hidden--xl { @include media-breakpoint-up(xl) { display: none !important; } }

[class*="visible-"] { display: none; }
.visible--xs { @include media-breakpoint-down(xs) { display: block !important; } }
.visible--sm { @include media-breakpoint-only(sm) { display: block !important; } }
.visible--md { @include media-breakpoint-only(md) { display: block !important; } }
.visible--lg { @include media-breakpoint-only(lg) { display: block !important; } }
.visible--xl { @include media-breakpoint-up(xl) { display: block !important; } }

.db { display: block; }
.mb0 { margin-bottom: 0 !important; }
.mb15 { margin-bottom: 15px !important; }

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

@mixin txt--up { text-transform: uppercase }
	%txt--up, .txt--up { @include txt--up }

@mixin txt--cap { text-transform: capitalize }
	%txt--cap, .txt--cap  { @include txt--cap }

@mixin txt--lg { font-size: 115% }
	%txt--lg, .txt--lg { @include txt--lg }

@mixin txt--md { font-size: $font-size-base }
	%txt--md, .txt--md { @include txt--md }

@mixin txt--sm { font-size: 85% }
	%txt--sm, .txt--sm { @include txt--sm }

@mixin txt--lh1 { line-height: 1.1em }
	%lh1, .lh1 { @include txt--lh1 }

@mixin lh125 { line-height: 1.25em }
	%lh125, .lh125 { @include lh125 }

@mixin txt--left { text-align: left }
	%txt--left, .txt--left { @include txt--left }

@mixin txt--right { text-align: right }
	%txt--right, .txt--right { @include txt--right }

@mixin txt--center { text-align: center }
	%txt--center, .txt--center { @include txt--center }

@if $font-weight-normal { .txt--wb0 { font-weight: $font-weight-normal } }
@if $font-weight-bold { .txt--wb1 { font-weight: $font-weight-bold } }
@if $font-weight-bolder { .txt--wb2 { font-weight: $font-weight-bolder } }

.color--text { color: $color-text; }
.color--white { color: $white; }
.color--primary { color: $color-primary; }
.color--secondary { color: $color-secondary; }
.color--highlight { color: $color-highlight; }
.color--link { color: $color-link; }
.color--review { color: $color-review; }
.color--success { color: $color-success; }
.color--danger { color: $color-danger; }
.color--warning { color: $color-warning; }
.color--info { color: $color-info; }

.bg--text { background-color: $color-text; }
.bg--white { background-color: $white; }
.bg--primary { background-color: $color-primary; }
.bg--secondary { background-color: $color-secondary; }
.bg--highlight { background-color: $color-highlight; }
.bg--link { background-color: $color-link; }
.bg--review { background-color: $color-review; }
.bg--success { background-color: $color-success; }
.bg--danger { background-color: $color-danger; }
.bg--warning { background-color: $color-warning; }
.bg--info { background-color: $color-info; }

%gdfancybg--text, .gdfancybg--text { @include gdfancy-bg($color-text); }
%gdfancybg--white, .gdfancybg--white { @include gdfancy-bg($white); }
%gdfancybg--primary, .gdfancybg--primary { @include gdfancy-bg($color-primary); }
%gdfancybg--secondary, .gdfancybg--secondary { @include gdfancy-bg($color-secondary); }
%gdfancybg--highlight, .gdfancybg--highlight { @include gdfancy-bg($color-highlight); }
%gdfancybg--review, .gdfancybg--review { @include gdfancy-bg($color-review); }
%gdfancybg--success, .gdfancybg--success { @include gdfancy-bg($color-success); }
%gdfancybg--danger, .gdfancybg--danger { @include gdfancy-bg($color-danger); }
%gdfancybg--warning, .gdfancybg--warning { @include gdfancy-bg($color-warning); }
%gdfancybg--info, .gdfancybg--info { @include gdfancy-bg($color-info); }