.block_main {
	padding: 1.5em 0;
	clear: both;

	.block_title {
		margin-top: 0;
		font-weight: 600;
	}

}
