.block_welcome {

	padding: 4em 0;
	background-image: url("/_/images/layout/welcome-sm.jpg");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	.wrap >:last-child { margin-bottom: 0; }

	p {
		max-width: 896px;
		margin: 0 auto 1em;
		font-size: 120%;
		font-weight: 800;
		line-height: 1.5em;
		text-align: center;
		color: $white;
		span {
			display: inline;
			padding: 0.15em;
			box-shadow: 	-0.3em 0 0 0 rgba(0,0,0,0.75),
							0.3em 0 0 0 rgba(0,0,0,0.75);
			background-color: rgba(0, 0, 0, 0.75);
		}
	}

	@include media-breakpoint-up(md) {
		background-image: url("/_/images/layout/welcome-md.jpg");
	}
	
	@include media-breakpoint-up(lg) {
		background-image: url("/_/images/layout/welcome-lg.jpg");
	}

}
