$banner-bg: 			$white !default;
$banner-color: 			$color-text;

.banner {
	padding: 2em 0 0.5em;
	border-bottom: 5px solid $color-secondary;
	box-shadow: 0 0.02em 0.75em 0 rgba(0, 0, 0, 0.5);
	background-color: $banner-bg;
	background: 	linear-gradient(to bottom, rgba($white, 0) 0%, rgba($black, 0.3) 15%, rgba($black, 0.1) 65%),
					linear-gradient(45deg, rgba($black, 0.1) 0%, rgba($black, 0) 30%),
					linear-gradient(-45deg, rgba($black, 0.1) 0%, rgba($black, 0) 30%),
					$white;
	color: $banner-color;
}