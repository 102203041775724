.block_company {
	background-color: $white;
	margin: 0 0 1.5em;
	padding: 1rem;
	text-align: center;
	border-radius: 4px;
	box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.3);

	.company-title {
		margin: -1rem -1rem 0.25em;
		padding: 0.5rem 1rem;
		border-radius: 4px 4px 0 0;
		background-color: $color-primary;
		color: $white;
	}

	.company-phone {
		font-size: 2rem;
		font-weight: 800;
		letter-spacing: -0.075rem;
		line-height: 1em;
		text-decoration: none;
		color: $color-secondary;
		&:hover {
			color: $color-secondary;
			cursor: default;
		}
	}

	.company-address {
		font-size: 0.95rem;		
		line-height: 1.1em;
	}

		.company-logo {
			margin: 0.5em auto;
		}

		.company-street {
			.license {
				display: block;
				margin-top: 10px;
				font-size: 100%;
			}
		}

	.company-coverage {
		background-color: #eee;
		margin: 1rem -1rem -1rem;
		padding: 1rem 1rem;
		font-size: 1rem;
		font-style: italic;
		line-height: 1.1em;
	}

	@include media-breakpoint-only(md) {
		.company-address {
			float: left;
			width: 42.5%;
		}
		.company-list {
			float: right;
			width: 56%;
			padding-top: 1rem;
		}
		.company-coverage {
			clear: both;
		}
	}

	@include media-breakpoint-down(sm) {
		.company-list li {
			padding: 0;
			background-image: none;
			&:before {
				display: inline-block;
				margin: 0 0.5rem 0 0;
				content: "\00bb";
				font-size: 1.3rem;
				font-weight: 800;
				color: $color-secondary;
			}
		}
	}

	@include media-breakpoint-up(md) {
		text-align: left;
		.company-logo { margin: 0.5em 0; }
	}

	@include media-breakpoint-up(lg) {
		.company-logo {
			float: right; 
			margin: 0 0 0.5em 0.5em;
			width: 45%;
		}
		.company-street {
			float: right;
			clear: both;
			width: 45%;
			text-align: right;
		}
	}

}

