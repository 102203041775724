.block_jumbo {

	@extend %gdfancybg--primary;
	border-bottom: 5px solid #fff;
	color: $white;

	@include media-breakpoint-up(lg) {
		.jumbo_marketing { padding-right: 5px; }
		.jumbo_conversion { padding-left: 5px; }
	}

}

	.jumbo_marketing {

		> *:first-child { margin-top: 0; }
		> *:last-child { margin-bottom: 0; }


		p {
			font-size: 1.3rem;
			line-height: 1.4em;
		}
		
		ul > li {
			font-size: 1.25rem;
			&:not(:last-child) { margin-bottom: 0.5em; }
		}

		ul > li:not(:last-child) {
			margin-bottom: 0.5em;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 1rem;
		}

		@include media-breakpoint-up(md) {
			p { font-size: 1.4rem; }
			ul > li { font-size: 1.35rem; }
		}

		@include media-breakpoint-up(lg) {
			p { font-size: 1.45rem; }
			ul > li { font-size: 1.4rem; }
		}

		@include media-breakpoint-up(xl) {
			p { max-width: 620px }
		}

	}

	.jumbo_conversion {

		.form_quote .form_title {
			line-height: 1em;
			background-color: $color-secondary;
			@include gradient-horizontal( darken($color-secondary, 8%), lighten($color-secondary, 7.5%), 0%, 75% );
		}

	}