// =====================================================
// VARIABLES - TOC
// -----------------------------------------------------
//
// 1. Grid - Bootstrap Grid Maps
//
// 2. Layout - zindex, position, padding, margin, radius, etc.
//
// 3. Typography - Font Styles
//
// 4. Colors - Universal Colors
//
// =====================================================




// =====================================================
// 1. GRID
// =====================================================
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
) !default;

$container-max-widths: (
  	sm: 540px,
  	md: 720px,
  	lg: 960px,
  	xl: 1140px
) !default;

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;




// =====================================================
// 2. LAYOUT VARIABLES
// =====================================================
$margin-sm: 	0.5em !default;
$margin-md: 	1em !default;
$margin-lg: 	2em !default;

$padding-sm: 	5px !default;
$padding-md: 	15px !default;
$padding-lg: 	30px !default;

$radius-sm: 	3px !default;
$radius-md: 	4px !default;
$radius-lg: 	6px !default;




// =====================================================
// 4. COLORS
// =====================================================
$white:  #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:  #000 !default;

$blue:    	#007bff !default;
$indigo:  	#6610f2 !default;
$purple:  	#6f42c1 !default;
$pink:    	#e83e8c !default;
$red:     	#dc3545 !default;
$orange:  	#fd7e14 !default;
$yellow:  	#ffc107 !default;
$green:   	#28a745 !default;
$teal:    	#20c997 !default;
$cyan:    	#17a2b8 !default;
$maroon: 	#8b1b2c !default;
$gold: 		#786a4b !default;

$color-primary: 		#3a6bb4; // blue;
$color-secondary: 		$orange; //#cf2727; // red;
$color-highlight: 		$blue !default;
$color-accent: 			$purple !default;
$color-review: 			$orange !default;
$color-success: 		$green !default;
$color-danger: 			$red !default;
$color-info: 			$indigo !default;
$color-warning: 		$yellow !default;

	// COLOR MODIFIES
	$color-lighten: 15% !default;
	$color-darken: 15% !default;
	$color-opac: 0.5 !default;


// THEME MAP - use function: theme(key, variant)
// ex: h1 { color: theme(primary, dark); }
// OR: div { background-color: theme(success, opac); }

$theme: (
	primary: (
		base: $color-primary,
		light: lighten($color-primary, $color-lighten),
		dark: darken($color-primary, $color-darken),
		opac: transparentize($color-primary, $color-opac)
	),
	secondary: (
		base: $color-secondary,
		light: lighten($color-secondary, $color-lighten),
		dark: darken($color-secondary, $color-darken),
		opac: transparentize($color-secondary, $color-opac)
	),
	success: (
		base: $color-success,
		light: lighten($color-success, $color-lighten),
		dark: darken($color-success, $color-darken),
		opac: transparentize($color-success, $color-opac)
	),
	danger: (
		base: $color-danger,
		light: lighten($color-danger, $color-lighten),
		dark: darken($color-danger, $color-darken),
		opac: transparentize($color-danger, $color-opac)
	),
	warning: (
		base: $color-warning,
		light: lighten($color-warning, $color-lighten),
		dark: darken($color-warning, $color-darken),
		opac: transparentize($color-warning, $color-opac)
	),
	info: (
		base: $color-info,
		light: lighten($color-info, $color-lighten),
		dark: darken($color-info, $color-darken),
		opac: transparentize($color-info, $color-opac)
	)
) !default;


// THEME COLOR DECLARATIONS
// -------------------------------------------
// These variable's values should be equal to
// something listed above - a THEME COLOR VARIABLE.

$body-bg:       						#f5f5f5;
$body-color:    						#333;

$color-text:							#333;
$color-h1:								null;
$color-h2:								null;
$color-h3:								null;
$color-h4:								null;
$color-h5: 								null;
$color-h6: 								null;
$color-link: 							$color-secondary;
$color-hover: 							darken($color-link, 10%);
$color-phone: 							$color-secondary;




// ===========================================
// 4. TYPOGRAPHY
// ===========================================

$font-family-sans-serif:  			"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       			"Times New Roman", Georgia, Times, serif;
$font-family-monospace:   			Menlo, Monaco, Consolas, "Courier New", monospace;

$font-family-default:				$font-family-sans-serif;
$font-family-mobile: 				"Helvetica Neue", Helvetica, Arial, sans-serif;

$font-size-base: 					16;
$base-line-height: 					1.5em;

$font-weight-normal:				400;
$font-weight-bold:					600;
$font-weight-bolder:				800;

