.title_branding {
	text-align: center;
	@include media-breakpoint-up(lg) {
		text-align: left;
	}
}

	.branding_logo {

		display: inline-block;
		padding: 0 10px;
		text-decoration: none;
		transform: scale(0.95);
		transition: transform 200ms;
		&:hover {
			transition: transform 200ms;
			transform: scale(1);
		}

		.logo_wrap {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			line-height: 1em;
			text-align: left;
			strong, span {
				display: inline-block;
			}
			strong {
				margin-right: 4px;
				font-size: 72px;
				line-height: 1em;
				font-weight: 800;
				color: $color-primary !important;
			}
			span {
				padding-top: 5px;
				font-size: 28px;
				letter-spacing: -0.0525em;
				line-height: 23px;
				font-weight: 600;
				color: $color-secondary !important;
			}
		}

	}